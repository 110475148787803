
/* important cause font is set here */
body,
button,
input,
select,
textarea {
	color: $color__text-main;
	font-family: $font__main;
	font-weight: 300;
	@include font-size(1.6);
	line-height: $font__line-height-body;
}


/* and then come the elements */
hr {
	background-color: $color__background-hr;
	border: 0;
	height: 1px;
	margin-bottom: 1em;
}

ul, ol { margin: 0 0 1em 0; }

ul { list-style: none; }

ol { list-style: decimal; }

dt { font-weight: bold; }

dd { margin: 0 1em 1em; }

img {
	height: auto; /* Make sure images are scaled correctly. */
	max-width: 100%; /* Adhere to container width. */
}

figure {
	margin: 0;
}

/* Make sure embeds and iframes fit their containers */
embed,
iframe,
object {
	max-width: 100%;
}

strong, b {
	color: #333;
	font-weight: bold;
	font-family: ParryProBold, serif;
}

em {
	font-style: italic;
	font-family: ParryProItalic, serif;
}

table {
	margin: 0 0 1em;
	width: 100%;
}

th { font-weight: bold; }

p { margin-bottom: 1em; }

dfn, cite, em, i {
	font-style: italic;
}

blockquote {
	margin: 0;

	p { font-style: italic;}
}

address {
	margin: 0 0 1em;
}

pre {
	background: $color__background-pre;
	font-family: $font__pre;
	@include font-size(1.5);
	line-height: $font__line-height-pre;
	margin-bottom: 1.6em;
	max-width: 100%;
	overflow: auto;
	padding: 1.6em;
}

code, kbd, tt, var {
	@include font-size(1.5);
	font-family: $font__code;
}

abbr, acronym {
	border-bottom: 1px dotted $color__border-abbr;
	cursor: help;
}

mark, ins {
	background: $color__background-ins;
	text-decoration: none;
}

sup,
sub {
	font-size: 75%;
	height: 0;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
}

sup {
	bottom: 1ex;
}

sub {
	top: .5ex;
}

small {
	font-size: 75%;
}

big {
	font-size: 125%;
}

/*
 *
 * FORMS
 *
 */
button,
input,
select,
textarea {
	font-size: 100%; /* Corrects font size not being inherited in all browsers */
	margin: 0; /* Addresses margins set differently in IE6/7, F3/4, S5, Chrome */
	vertical-align: baseline; /* Improves appearance and consistency in all browsers */
}

/*
 *
 * FORM > FIELDS
 *
 */

input[type="checkbox"],
input[type="radio"] {
	padding: 0; /* Addresses excess padding in IE8/9 */
}

input[type="search"] {
	-webkit-appearance: textfield; /* Addresses appearance set to searchfield in S5, Chrome */
	-webkit-box-sizing: content-box; /* Addresses box sizing set to border-box in S5, Chrome (include -moz to future-proof) */
	-moz-box-sizing:    content-box;
	box-sizing:         content-box;
}

input[type="search"]::-webkit-search-decoration { /* Corrects inner padding displayed oddly in S5, Chrome on OSX */
	-webkit-appearance: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner { /* Corrects inner padding and border displayed oddly in FF3/4 www.sitepen.com/blog/2008/05/14/the-devils-in-the-details-fixing-dojos-toolbar-buttons/ */
	border: 0;
	padding: 0;
}

input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
textarea {
	color: $color__text-input;
	border: 1px solid $color__border-input;
	//@include border-radius(3px);
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
textarea:focus {
	color: $color__text-input-focus;
}

input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"] {
	padding: 3px 9px 2px;
}

textarea {
	overflow: auto; /* Removes default vertical scrollbar in IE6/7/8/9 */
	padding-left: 3px;
	vertical-align: top; /* Improves readability and alignment in all browsers */
	width: 100%;
}

/*
 *
 * FORM > BUTTONS
 *
 */

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
	border: 1px solid;
	border-color: $color__border-button;
	//@include border-radius(3px);
	background: $color__background-button;
	color: rgba(0, 0, 0, .8);
	cursor: pointer; /* Improves usability and consistency of cursor style between image-type 'input' and others */
	-webkit-appearance: button; /* Corrects inability to style clickable 'input' types in iOS */
	@include font-size(1.6);
	line-height: 2;
	padding: 3px 9px 2px;
}

button:hover,
input[type="button"]:hover,
input[type="reset"]:hover,
input[type="submit"]:hover {
	border-color: $color__border-button-hover;
}

button:focus,
input[type="button"]:focus,
input[type="reset"]:focus,
input[type="submit"]:focus,
button:active,
input[type="button"]:active,
input[type="reset"]:active,
input[type="submit"]:active {
	border-color: $color__border-button-focus;
}