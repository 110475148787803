.Tabs {
	clear: left;
	width: 100%;
	padding-top: 8px;
	overflow: hidden;
	padding-bottom: 16px;
	border-bottom: 1px solid $color-nuit;
	margin-bottom: 32px;

	&-item {
		@include respond-max($medium-) {
			width: 50%;
		}
		display: block;
		width: 33.33%;
		float: left;
	}

	&-tab {
		color: $color-nuit!important;

		&:before {
			content: "";
			display: inline-block;
			width: 16px;
			height: 16px;
			margin-right: 8px;
			position: relative;
			top: 2px;
			background-color: $color-nuit;
		}

		&.selected,
		&[aria-selected='true'],
		&:hover {
			color: $color-or!important;

			&:before { background-color: $color-or; }
		}

	}

	&-panel {
		padding-bottom: 16px;
		border-bottom: 1px solid $color-nuit;
	}
}

div[aria-hidden='true'] {
  display: none;
}