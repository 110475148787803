.Hero {
	padding-top: 15px;
	margin-bottom: 8px;

	&--slideshow {
		border-bottom: 1px solid $color-nuit;
		padding-bottom: 15px;

		.slideshow_container {
			z-index: index($els, slideshow);
			
			.slideshow_button {
				width: 23px;
				height: 15px;
				background-position: center top!important;
				opacity: 1;

				&:hover {
					background-position: center -15px!important;
				}

				@include respond-max($large) {
					display: none!important;
				}
				
				&.slideshow_previous {
					left: -33px;
					background-image: url(assets/img/arrow-left-2.png)!important;
				}
				&.slideshow_next {
					right: -33px;
					background-image: url(assets/img/arrow-right-2.png)!important;
				}
			}
		}
	}

	&-img,
	&--image img {
		display:block;
		width: 100%;
		// max-width: 600px; /* for the illustration */
		margin-left: auto;
		margin-right: auto;
		height: auto;
		// padding: 24px 0; /* for the illustration */
	}

	@at-root {
		// .Hero { margin-bottom: 32px;}
	}
}