.Footer {
	margin-top: 38px;
	padding-top: 13px;
	border-top: 1px solid $color-nuit;
	overflow: hidden;
	clear: both;
}

.Footer-glowing {
	width: 100%;
	// @include font-size(1.3);
	// line-height: 1;
	padding-bottom: 13px;
	border-bottom: 1px solid $color-nuit;
	margin-bottom: 38px;

	a { color: $color-or;}
}

.Footer-newsletter {
	margin-bottom: 2em;
	overflow: hidden;

	p { overflow: hidden; }

	h4 {
		@include font-size(1.6);
		padding: 3px 0 2px;
		margin-right: 1em;
		margin-bottom: .5em;

		@include respond-min($medium) {
			line-height: 2;	
		}
	}

	h4,p { float: left;}

	label { display: none;}

	input { 
		border-radius: 0;
		font-family: $font__main;
		display: block;
		float: left;

		&[type="email"] {
			border-right: 0;
			border-color: $color-nuit;
		}

		&[type="submit"] {
			font-weight: bold;
			color: $color-or;
			background-color: $color-nuit;
			border-color: $color-nuit;

			&:hover {
				color: $color-nuit;
				background-color: $color-or;
			}
		}
	}
}

.Footer {

  & > .links {
    width: 100%;
    float: left;
    @include font-size(1.3);
    line-height: 1;
  
    @include respond-max($large-) { line-height: 1.5; }
  }
}

.Address {
	display: block;
	width: 90%;
	float: left;
	
	@include font-size(1.3);
	line-height: 1;

	@include respond-max($large-) { line-height: 1.5; }

	span {
		display: block;
		float: left;

		&.Adress-tel {
			@include respond-max($large-) { clear: left; }
		}
	}

	color: $color-nuit;

	a {
		color: $color-nuit;

		&:hover { color: $color-or; }
	}
}