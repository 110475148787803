.Lesson {
	clear: both;
	@include font-size(1.7);
	color: $color-nuit;

	br, p { display: none;}

  @include media('>=medium') {
    display: flex;
    align-items: center;
  }

	&.isCancelled {
		.Lesson-teacher,
		.Lesson-hours,
		.Lesson-title {
			text-decoration: line-through;
			color: $color-grey-light;
		}
	}

	&-teacher {
		@include respond-min($medium) { width: 145px; }
	}

	&-teacher,
	&-hours,
	&-title { display: block; float: left; }

	&-teacher:after { content: " : ";}
	&-hours:after { content: "\00A0/\00A0";}

	&-title {
		em {@include font-size(1.3);}
		a {

			color: $color-or;
			&:hover { text-decoration: underline; }
		}
	}
}