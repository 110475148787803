/*
 *
 * Custom colors
 *
 */

$color-black: #000;
$color-white: #fff;
$color-grey: #aaa;
$color-grey-dark: #333;
$color-grey-light: #ccc;

$black: $color-black;
$white: $color-white;

$color-main: $color-grey-dark;
$color-aside: $color-grey;
$color-border: #ccc;

$color-brown: #8a8277;

$color-brown-light: rgb(244,234,224);

$color-nuit: rgb(30,40,80);
$color-or: rgb(171,168,54);

/*
 *
 * Reset and Elements colors
 *
 */

/* Reset and Elements background colors */
$color__background-body: #fff;
$color__background-hr: #ccc;
$color__background-button: #e6e6e6;
$color__background-pre: #eee;
$color__background-ins: #fff9c0;

/* Reset and Elements form colors */
$color__text-input: #666;
$color__text-input-focus: #111;

/* Reset and Elements links colors */
$color__link: $color-brown;
$color__link-visited: $color-grey-light;
$color__link-hover: $color-grey;

/* Reset and Elements main color */
$color__text-main: $color-grey-dark;

/* Reset and Elements border colors */
$color__border-button: #ccc #ccc #bbb;
$color__border-button-hover: #ccc #bbb #aaa;
$color__border-button-focus: #aaa #bbb #bbb;
$color__border-input: #ccc;
$color__border-abbr: #666;


$color__title: #666;

