.Title {
	display: block;
	width: auto;
	float: left;
	clear: left;
	line-height: 1;
	text-transform: uppercase;
	border-width: 0 0 1px 0;
	border-style: solid;

	&--h1 {
		margin-top: 32px;
		margin-bottom: 24px;
		color: $color-nuit;
		border-color: #b3b5c9;
	}

	@at-root {
		.Title--h1 { margin-bottom: 0; }
	}
}