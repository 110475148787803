		h1, h2, h3 {
			display: block;
			width: auto;
			float: left;
			clear: left;
			line-height: 1;
			text-transform: uppercase;
			border-width: 0 0 1px 0;
			border-style: solid;
			//
			margin-bottom: 1em;
		}

		h1 {
			color: $color-nuit;
			border-color: #b3b5c9;
		}

		h2 {
			color: $color-or;
			border-color: $color-or;
			border-color: #e6e5c3;
		}

		h3 {
			text-transform: none;
			color: $color-nuit;
			border-color: #b3b5c9;
		}

		p {
			clear: both;
			margin-bottom: 24px;
			max-width: 34em;

			&:last-of-type { margin-bottom: 0; }
		}

		p { max-width: 100%;}

		img, p img { width: 100%; }

		a {
			color: $color-or;

			&:hover { color: $color-nuit;}
		}

		hr {
			margin-bottom: 38px;
			color: $color-nuit;
			border-color: $color-nuit;
			background-color: $color-nuit;
		}

		.wp-caption { min-width: 100%; }

		.wp-caption-text,
		.wp-caption-dd {
			font-style: italic;
			@include font-size(1.4);
			text-align: right;
		}