.u-mobile-only {
	@include respond-min($medium) {
		display: none;
	}
}

.u-max-only {
	display: none;

	@include respond-min(50em) {
		display: block;
	}
}