.Content {
	clear: both;
	
	.Copy {
		padding: 32px 0 36px;
		@include font-size(1.7);
		line-height: 1.5;
		color: $color-nuit;

		h1, h2, h3 {
			display: block;
			width: auto;
			float: left;
			clear: left;
			line-height: 1;
			text-transform: uppercase;
			border-width: 0 0 1px 0;
			border-style: solid;
		}

		h1 {
			margin-bottom: 24px;
			color: $color-nuit;
			// border-color: $color-nuit;
			border-color: #b3b5c9;
		}

		h2 {
			color: $color-or;
			border-color: $color-or;
			border-color: #e6e5c3;
		}

		h3 {
			text-transform: none;
			color: $color-nuit;
			border-color: #b3b5c9;
		}

		p {
			clear: both;
			margin-bottom: 24px;
			max-width: 34em;

			@at-root {
				.page-les-prix .Copy p { max-width: 100%; }
				.page-la-boutique .Copy p { max-width: 83.5%; }
			}

			&:last-of-type { margin-bottom: 0; }
		}

		a {
			color: $color-or;

			&:hover { color: $color-nuit;}

			&.button {
				@include font-size(1.3);
				color: $color-or!important;

				&:after {
					position: relative;
					bottom: -1px;
					content: "\00A0\003E";
				}
				
				&:hover {
					color: $color-nuit!important;

					&:after { color: $color-nuit;}
				}

				&--special {
					display: block;
					width: 100%;
					margin-top: -18px;
					padding-bottom: 16px;
					border-bottom: 1px solid $color-or;
					@include font-size(1.7);
					line-height: 1;
				}
			}
		}

		hr {
			margin-bottom: 38px;
			color: $color-nuit;
			border-color: $color-nuit;
			background-color: $color-nuit;
		}
	}
}