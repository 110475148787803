.Splash {
  display: none;
	width: 100%;
	height: 100%;
	position: fixed;
	z-index: index($els, splash);
	background-color: $color-nuit;
	transition: transform .8s, opacity .9s;

	&:hover { cursor: pointer;}

	&-wrapper {
		width: 100%;
		max-width: 868px;
		position: absolute;
		height: 70vh;
		top: 15vh;
		left: 50%;
		transform: translateX(-50%);

		@include respond-min($larger-) {
			border-width: 1px 0 1px 0;
			border-style: solid;
			border-color: $color-or;
		}
	}

	&-logo {
		width: 261px!important;
		padding: 0;
		@include respond-min(25em) { width: 332px!important; }
		position: absolute;
		top: 20%;
		left: 50%;
		transform: translate(-50%, -20%);

		@include respond-min($larger-) {
			// top: 25%;
			// transform: translate(-50%, -25%);
		}

		.Logo-img {
			width: 261px!important;
			margin-bottom: 28px;
			@include respond-min(25em) {
				width: 332px!important;
			}
			height: auto;
		}

		.Splash-poem {
			@include font-size(1.4);
			@include respond-min(25em) {
				@include font-size(1.8);
			}
			line-height: 1.25;
			text-align: center;
			text-transform: uppercase;
			color: $color-or;
		}
	}

	&-skip {
		position: absolute;
		left: 50%;
		bottom: 5%;
		transform: translate(-50%, -5%);
		@include respond-min($larger-) {
			bottom: 8%;
			transform: translate(-50%, -8%);
		}
		
	}
}