.Logo {
  display: block;
  margin: 0 auto;
  padding: 26px 0 24px;
  width: 220px;
  @include respond-min(25em) {
  	width: 261px;
  }

	&--header {
  	position: relative;
  	z-index: index($els,logo);
  }
}