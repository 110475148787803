.notification {
  margin-top: 24px;
  padding: 20px;
  border: 1px solid $color-nuit;
  background-color: lighten($color-nuit, 70%);

  > p {
    margin-bottom: 0;
    overflow: hidden;

    img {
      &.alignleft {
        margin-right: 20px;
        float: left;
      }
    }
  }

  a {
    color: $color-or;
    font-family: ParryProBold;

    &:hover { text-decoration: underline; }
  }
}
