.sticky-wrapper {
  z-index: index($els, sticky);
}

/* start showing at 962px / 60.125em */

.Menu {
	text-transform: uppercase;
	@include font-size(1.5);
	line-height: 1;
	// used when sticky
	z-index: index($els, menu);

	&-toggle {
		@include respond-min($larger) { display: none;}
		display: block;
	  width: 34px;
	  height: 28px;
	  padding: 5px;
	  position: fixed;
	  top: 1em; right: 1em;
	  // z-index: index($els, menuToggle);
	  z-index: index($els, menuToggle);
	  transform: rotate(0deg);
	  transition: .3s ease-in-out;
	  cursor: pointer;
	  background-color: transparent;
	  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

	  // border: 1px solid $color-or;
	  border-radius: 3px;

		span {
			-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
		  display: block;
		  position: absolute;
		  height: 1px;
		  width: 25px;
		  background: $color-or;
		  border-radius: 9px;
		  opacity: 1;
		  left: 5px;
		  transform: rotate(0deg);
		  transition: .25s ease-in-out;

			&:nth-child(1) { top: 4px; }

			&:nth-child(2),
			&:nth-child(3) { top: 11px; }

			&:nth-child(4) { top: 18px; }
		}

		&.isOpen span {

			&:nth-child(1) {
			  top: 12px;
			  width: 0%;
			  left: 50%;
			}

			&:nth-child(2) { transform: rotate(45deg); }

			&:nth-child(3) { transform: rotate(-45deg); }

			&:nth-child(4) {
			  top: 12px;
			  width: 0%;
			  left: 50%;
			}
		}
	}

	.Wrapper {
		padding-top: 15px;
		margin-bottom: 0;
		padding-bottom: 11px;
		border-bottom: 1px solid $color-nuit;
		background-color: white;
		text-align: center;
	}

	.menu-item {
		display: inline-block;
		list-style-type: none;

		&:after {
			content: "\25CF";
			display: inline-block;
			margin: -2px 4px 0 9px;
			vertical-align: top;
			color: $color-or;
		}

		&:last-of-type:after,
		&.nav-contact:after { content: none;}

		&.current-menu-item a,
		&.current_page_item a { color: $color-or;}
	}

	a {
		color: $color-nuit;

		&:hover { color: $color-or;}
	}

	@include respond-min($larger) {
		.menu-item {
			&.lang-item-fr,
			&.lang-item-en {
				position: absolute;
				top: 1em;
				right: 1em;

				a { color: darken($color-or, 20%)}

				&.current-lang a {
					color: $color-or;;
				}
			}
			&.lang-item-fr {
				right: 3em;
			}
		}
	}

	@include respond-max($larger-) {
		width: 100%;
		height: 100vh;
		margin-top: 0;
		position: absolute;
		top: 0; right: 0; bottom: 0; left: 0;
		z-index: -1;
		opacity: 0;
		background-color: $color-nuit;
		transition: opacity .3s;
		
		&.isOpen {
			z-index: index($els, menu);
			opacity: 1;
		}

		.Wrapper {
			width: 100%;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			text-align: left;
			border-bottom: 0;
			background-color: $color-nuit;
		}

		.Menu-item, .menu-item {
			// @include font-size(1.6);
			display: block;
			width: 100%;
			margin: .5em 0;
			padding: .5em 0 0 56px;

			&:after {
				content: "";
			}

			&:before {
				content: "\25CF";
				display: inline-block;
				margin: 0 9px 0 4px;
				vertical-align: middle;
				color: $color-nuit;
			}

			&.lang-item-fr {
				margin-top: 3em;
			}

			&.current-menu-item:before,
			&.current_page_item:before {
				color: $color-or;
			}

			a {
				color: $color-or;
				opacity: .8

				&:hover { opacity: 1;}
			}
		}
	}

}