.Content .Blog {
	@include outer-container(100%);
	width: 100%;
	padding-top: 40px;

	&-articles {
		@include respond-min($medium) {
			@include span-columns(8);
			@include shift(2);
		}
	}

	&-instagram {
		@include span-columns(12);
		@include omega;
		border-top: 1px solid $color-nuit;
		padding: 40px 0 80px;
	}

	&-date {
		display: block;
		margin-bottom: 1em;
	}

	&-copy {
		padding-top: 0!important;

		.Title--h1 {
			width: 100%;
			margin-top: 0;
			color: $color-or;
			border-color: $color-or;
		}

		@import "EditedStyles";

	}
}