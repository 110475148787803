.page-template-page-presse {

  h1 {
    display: block;
    padding-top: 32px;
    width: auto;
    float: left;
    clear: left;
    line-height: 1;
    text-transform: uppercase;
    border-width: 0 0 1px;
    border-style: solid;
    margin-bottom: 24px;
    color: #1e2850;
    border-color: #b3b5c9;
  }
}

.parutions-presse {
  @include clearfix;
  display: flex;
  flex-wrap: wrap;
  padding-top: 0!important;

  & > .parution {

    @include media('>=small', '<large') {
      @include span-columns(6);
      @include omega(2n);
    }

    @include media('>=large') {
      @include span-columns(4);
      @include omega(3n);
    }

    display: flex;
    flex-wrap: wrap;

    & .-wrap {
      display: flex;
      flex-wrap: wrap;
      height: 100%;
    }
  }

  & .image {
    align-self: flex-start;
    
    backface-visibility: hidden;
  }

  & .link {
    
    .image {
      opacity: 1;
      transition: opacity .3s;
    }

    &:hover {
      
      .image { opacity: .6; }
    }
  }
  & .caption {
    width: 100%;
    padding: 1em .5em 2.5em;
    align-self: flex-end;
    color: $color-nuit;

    & > .name,
    & > .date {
      display: block;
      text-align: center;
    }

    & > .name {
      @include font-size(2);
      font-weight: bold;
    }
  }

}