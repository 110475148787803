.Program {
	margin-bottom: 24px;
	@include font-size(1.7);
	border-bottom: 1px solid $color-or;

	&-title {
		margin-bottom: 24px;
		line-height: 1;
		text-transform: uppercase;
		color: $color-nuit;
		border-bottom: 1px solid #e6e5c3;
	}

	&-list { margin-bottom: 0;}

	&-item {
		clear: both;
		overflow: hidden;
		margin-bottom: 24px;

		&:last-of-type { margin-bottom: 0;}
	}

	&-day {
		display: inline-block;
		line-height: 1;
		text-transform: uppercase;
		color: $color-or;
		border-bottom: 1px solid #e6e5c3;
	}

	&-more {
		p, > div {
			@include font-size(1.3);
			max-width: 600px;
		}
		a { color: $color-or; }
	}
}