/*!
Theme Name: Ici Selfcare
Description: Theme for Ici Selfcare
Author: Wanja Ledowski et Jean-Baptiste Louvet
Version: 0.1
*/


// libraries
@import 'vendors/bourbon/bourbon';
@import 'vendors/neat/neat';

// mixins, functions and stuff
@import 'utils/mixins';
@import 'utils/includeMedia';

// variables
@import 'var/colors';
@import 'var/typography';
@import 'var/mediaQueries';

// reset
@import 'reset';

// utils
@import 'utils/utils';

@import 'modules/Zindex';

// basic styling of elements
@import 'base/elements';
@import 'base/links';
@import 'modules/Title';

// layout
@import 'modules/splash';
@import 'modules/Site';

@import 'modules/Wrapper';

@import 'modules/Header';
@import 'modules/Logo';
@import 'modules/Slogan';

@import 'modules/Grid';

@import 'modules/Hero';

@import 'modules/Tabs';

@import 'modules/Menu';

@import 'modules/Content';

@import 'modules/notification';

@import 'modules/Program';
@import 'modules/Lesson';

@import 'modules/Mindbody';

@import 'modules/Prices';

@import 'modules/Blog';

@import 'modules/Footer';
@import 'modules/Social';

@import 'modules/PageBoutique';

@import 'modules/parutions-presse';

@import 'modules/module-notification';

// print
@import 'base/print';
