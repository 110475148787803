.Prices {
	margin-bottom: 24px;
	border-bottom: 2px solid $color-or;
	@include font-size(1.7);
	@include respond-min($small) {
		@include font-size(2.1);
	}
	color: $color-or;

	&-title {
		margin-bottom: 24px;
		@include font-size(2.2);
		@include respond-min($small) {
			@include font-size(2.6);
		}
		line-height: 1;
		text-transform: uppercase;
		color: $color-nuit;
		border-bottom: 1px solid $color-or;
	}

	h2 {
		display: inline-block;
		@include font-size(1.7);
		@include respond-min($small) {
			@include font-size(2.1);
		}
		line-height: 1;
		text-transform: uppercase;
		color: $color-nuit;
		border-bottom: 1px solid $color-nuit;
	}

	li {
		&:before {
			color: $color-nuit;
			content: "– ";
		}

		&:last-of-type {
			margin-top: 24px;
			&:before { content: "";}
		}
	}

	a {
		color: $color-nuit;
		&:hover { color: $color-or; }
	}
}