@font-face {
    font-family: 'ParryProItalic';
    src: url('assets/fonts/parry-normalitalic-webfont.eot');
    src: url('assets/fonts/parry-normalitalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('assets/fonts/parry-normalitalic-webfont.woff2') format('woff2'),
         url('assets/fonts/parry-normalitalic-webfont.woff') format('woff'),
         url('assets/fonts/parry-normalitalic-webfont.ttf') format('truetype'),
         url('assets/fonts/parry-normalitalic-webfont.svg#parrynormalitalic') format('svg');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'ParryProBold';
    src: url('assets/fonts/parry-bold-webfont.eot');
    src: url('assets/fonts/parry-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('assets/fonts/parry-bold-webfont.woff2') format('woff2'),
         url('assets/fonts/parry-bold-webfont.woff') format('woff'),
         url('assets/fonts/parry-bold-webfont.ttf') format('truetype'),
         url('assets/fonts/parry-bold-webfont.svg#parrybold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@include font-face(ParryPro, "assets/fonts/ParryPro-Normal", 400, normal, eot woff ttf);

$font__main: ParryPro, times, "Times new roman", serif;
$font__alt: "Times new roman", times, serif;

$font__title: $font__main;

$font__code: Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace;
$font__pre: "Courier 10 Pitch", Courier, monospace;

$font__line-height-body: 2;
$font__line-height-pre: 1.6;

/*
base font : 12/19
small font : 10/16
title font : 14/22
*/

%font-average {
	@include font-size(1.2);
	line-height: 1.583333333;
}

%font-small {
	@include font-size(1);
	line-height: 1.6;
}

%font-title {
	@include font-size(1.4);
	line-height: 1.571428571;
}