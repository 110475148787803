.Grid {
	width: 100%;
	overflow: hidden;

	&-one-of-four {
		padding-bottom: 24px;

		@include respond-min($medium) {
			width: 50%;
			float: left;
		}

		@include respond-min($large) {
			width: 25%;
			float: left;

			&:nth-child(4n + 1) { clear: left; }
		}
		
		img {
			display: block;
			width: 100%;
			max-width: 212px;
			margin-bottom: 1em;
			
			& ~ p:first-of-type { display: none;}
		}
	}
}