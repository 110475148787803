.Social {
	display: block;
	float: right;
	width: 10%;
	margin-top: -5px;
	text-align: right;

	&-network {
		display: inline-block;
		vertical-align: top;
		width: 16px;
		height: 16px;
		margin-left: 3px;

		&:hover { opacity: .8;}
	}
}